import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MenuNav from "../../components/menus/menuNav"
import HogRoastMenu from "../../components/menus/hogRoast"

const HeaderSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home-feature-chicken.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="page-header"
          fluid={imageData}
          backgroundColor="#f2f3f3"
        >
          <Row>
            <Col xs={{ span: 6, offset: 3 }} md={{ span: 4, offset: 4 }} className="feature-bg">
              <h2>
                Hog Roast Menu
              </h2>
            </Col>
          </Row>
        </BackgroundImage>
      )
    }}
  />
)

const HogRoastMenuPage = () => (
  <Layout pageInfo={{ pageName: "Hog Roast Menu" }}>

    <SEO title="Hog Roast Menu" keywords={[
      `flame and hog`,
      `hog roast`,
      `lamb roast`,
      `bbq catering`
    ]} />

    <div className="grey-bg">
      <HeaderSection/>
      <Container>
        <MenuNav />
        <Row className="global-padding">
          <Col md={12} className="menu-bg">
            <HogRoastMenu/>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default HogRoastMenuPage
