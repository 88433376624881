import React from "react";
import { Nav } from "react-bootstrap"

const MenuNav = () => {
  return (
    <>
      <Nav fill className="justify-content-center" defaultActiveKey="/menus/hog-roast-menu">
        <Nav.Item>
          <Nav.Link eventKey="hog" href="/menus/hog-roast-menu">Hog Roast Menu</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="lamb" href="/menus/lamb-roast-menu">Lamb Roast Menu</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="bbq" href="/menus/bbq-menu">BBQ Menu</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="salads" href="/menus/salad-menu">Salads</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="deserts" href="/menus/desert-menu">Desserts</Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default MenuNav;