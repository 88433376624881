import React from "react"

const HogRoastMenu = () => {
  return (
    <>

      <div className="menu_item">
        <h4>Traditional Style</h4>
        <p>A whole pig, seasoned with our secret butt rub and slowly cooked. Served with a choice of either brioche,
          crusty or floured rolls with our homemade stuffing, apple sauce & crackling.</p>

        <div className="pricing">
          <span>For up to 100 people: £845 all inclusive</span>
          <span>For up to 150 people: £1095 all inclusive</span>
          <span>Over 150 people: POA</span>
        </div>
      </div>

      <div className="menu_item">
        <h4>Mexican Style</h4>
        <p>Nibble on some cheesy nachos to get things started.</p>
        <p>A whole pig, seasoned with our deep flavoured Mexican rump rub and slowly cooked. Served with soft tortillas, salsa, guacamole, sour cream & pickled green chilli. Not to forget the cracking.</p>

        <div className="pricing">
          <span>For up to 100 people: £945 all inclusive</span>
          <span>For up to 150 people: £1245 all inclusive</span>
          <span>Over 150 people: POA</span>
        </div>
      </div>

      <div className="menu_item">
        <h4>Half Traditional Hog Half Mexican Style Hog</h4>

        <div className="pricing">
          <span>For up to 100 people: £1045 all inclusive</span>
          <span>For up to 150 people: £1345 all inclusive</span>
          <span>Over 150 people: POA</span>
        </div>
      </div>

      <div className="menu_item">
        <h4>Caribbean Style</h4>
        <p>A whole pig, seasoned with a mild jerk butt rub, (this can be spiced up on the day for individuals) and slowly cooked. Served with warm roti bread & Mango Salsa. And of course crackling.</p>

        <div className="pricing">
          <span>For up to 100 people: £945 all inclusive</span>
          <span>For up to 150 people: £1245 all inclusive</span>
          <span>Over 150 people: POA</span>
        </div>
      </div>

      <div className="menu_item">
        <h4>Mediterranean Style</h4>
        <p>A whole pig with our own Mediterranean butt rub, served on crusty French bread accompanied with roasted Mediterranean vegetables. Not to forget the crackling.</p>

        <div className="pricing">
          <span>For up to 100 people: £945 all inclusive</span>
          <span>For up to 150 people: £1245 all inclusive</span>
          <span>Over 150 people: POA</span>
        </div>
      </div>

      <div className="menu_item">
        <h4>Mini Hog</h4>
        <p>Price based on Traditional Hog. Price will vary if you want a different style hig. Please not that this is <strong>not</strong> available on Saturday's and bank holiday Sunday's.</p>

        <div className="pricing">
          <span>For up to 50 people: £700</span>
        </div>
      </div>


    </>
  )
}

export default HogRoastMenu